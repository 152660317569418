import React, {  useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import backIcon from '../assests/icons/backspaceIcon.png'
import {useLocation} from "react-router-dom";
import { Outlet } from "react-router-dom";
import PrivateRoute from "../privateRoute";

export default function Layout(props) {
    const location = useLocation();
    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }, [location.pathname]);
  return (
    <>
    
        <Container className="main-layout">
            <Row>
                <Col xs={1}>
                <img src={backIcon} alt="backIcon" className=""/>
                </Col>
                <Col xs={11} className=" d-flex  justify-content-center">
                <b>Health assessment</b>
                </Col>
            </Row>
      <PrivateRoute>
          <div className= "main-body fixed">
            <Outlet></Outlet>
          </div>
      </PrivateRoute>

        </Container>
    </>
  );
}
