import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "./layout";
import HealthAssessment from "./pages/healthAssesment";
import './App.scss'
import HealthActivities from "./pages/healthActivities";
function App() {
  return (
    <div className="App">
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout/>}>
      <Route path="/healthAssessment" exact element={<HealthAssessment/>}/>
      <Route path="/healthActivities" exact element={<HealthActivities/>}/>
      </Route>
    </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
