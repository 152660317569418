import React from 'react'

function HealthAssessment() {
  return (
    <div>
     <p>Health Assessment</p>
    </div>
  )
}

export default HealthAssessment
