import React from 'react'

function HealthActivities() {
  return (
    <div>
     <p>Health Activities</p>
    </div>
  )
}

export default HealthActivities
