import React from "react";
import { Navigate } from "react-router-dom";
import { isLogedIn } from "../library";
// import { isLogedIn } from "../api";



const PrivateRoute = ({ children }) => {
  if (!isLogedIn()) {
    return <Navigate to="/" replace />;
  }

  return children;
};
export default PrivateRoute;
